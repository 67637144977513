import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, throwError, timer } from 'rxjs';
import { catchError, concatMap, retryWhen } from 'rxjs/operators';
import { NotificationService } from '../core/services/notification.service';
import { ApiResponseErrorCode } from '../model/dtos';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';
import { InterceptorHttpParams } from './InterceptorHttpParams';

export class ApiResponseException {
   public constructor(init?: Partial<ApiResponseException>) {
      Object.assign(this, init);
   }

   public get errorSubCode(): any {
      return this.problemDetails?.extensions?.errorSubCode;
   }

   public errorCode: ApiResponseErrorCode;
   public displayMessage: string;
   public request: HttpRequest<any>;
   public error: any;
   public problemDetails: any;
   public suppressNotification: boolean;
}

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor {
   private readonly tourId: number = 114292;

   constructor(
      private auth: AuthService,
      private notificationService: NotificationService,
      private configService: ConfigService
   ) {}

   public intercept(request: HttpRequest<any>, next: HttpHandler) {
      return next.handle(request).pipe(
         retryWhen(
            concatMap((error, retryAttempt) => {
               // Only apply retry logic to our own API requests
               if (!this.configService.apiUtil.isHapticApiRequest(request)) return throwError(error);

               // Only retry rate limited requests or if the backend is unavailable
               if (error.status !== 429 && error.status !== 503) return throwError(error);

               retryAttempt++;
               if (retryAttempt > 3) return throwError(error);

               let retryAfterMs = 2000 * Math.pow(2, retryAttempt);

               const headerRetryAfterSecStr = error.headers?.get('Retry-After');
               if (headerRetryAfterSecStr) {
                  const headerRetryAfterSec = Number(headerRetryAfterSecStr);
                  // Only respect backend header if wait time is reasonable
                  if (headerRetryAfterSec <= 30) retryAfterMs = headerRetryAfterSec * 1000;
               }

               // Retry should be only attempted if wait time is reasonable
               if (retryAfterMs > 30 * 1000) return throwError(error);

               retryAfterMs += this.jitter(500, 2000);

               console.log(`Attempting retry ${error.url} in ${retryAfterMs}ms`);
               return timer(retryAfterMs);
            })
         ),
         catchError(async (err) => {
            // TODO: This error handler should be haptic API only

            // don't fail on requests outside our api or on the proxy endpoint
            if (
               err.status === 401 &&
               this.configService.apiUtil.isHapticApiRequest(request) &&
               !this.configService.apiUtil.isLoginRequest(request) &&
               !this.configService.apiUtil.isProxyRequest(request) &&
               !this.configService.apiUtil.isHapticFileRequest(request)
            ) {
               return from(this.auth.logout());
            }

            if (!this.configService.apiUtil.isHapticApiRequest(request)) return throwError(err);

            // If angular expects a Blob response but backend responds with an error json
            if (err.error instanceof Blob) {
               err.error = JSON.parse(await err.error.text());
            }

            // not sure which variant is the correct one for backend error messages intended to be displayed
            // we shouldn't display notifications for *all* failed http requests
            let message: string = null;
            if (err.error) {
               if (err.error.extensions?.errorCode) err.errorCode = err.error.extensions.errorCode;

               // TODO use new exception codes with error code and other new extensions
               message = err.error.title;

               if (err.error.errors) {
                  message += Object.keys(err.error.errors)
                     .map((k, i) => err.error.errors[k])
                     .join(' ');
               }

               if (err.error.detail) {
                  message += ': ' + err.error.detail;
               }
            }

            const displayError = message || err.message;

            console.error('Http request error', err);

            const exception = new ApiResponseException({
               displayMessage: displayError,
               errorCode: err.errorCode,
               problemDetails: err.error,
               request,
               error: err,
               suppressNotification:
                  request.params instanceof InterceptorHttpParams && request.params?.interceptorConfig?.suppressNotification
            });

            // TODO: displayMessage should be localized string based on "errorCode" and include validation errors
            if (displayError) {
               // Gives us the possibility to suppress a notifcation
               setTimeout(() => {
                  this.handleErrorDisplay(exception, displayError);
               }, 0);
            }

            // Async catchError needs to throw exception instead returning from observable
            throw exception;
         }),
         catchError((err) => {
            return throwError(err);
         })
      );
   }

   private handleErrorDisplay(exception: ApiResponseException, displayError: string) {
      if (exception?.suppressNotification) return;

      if (exception?.errorCode === ApiResponseErrorCode.EntityLimit) {
         if (exception?.errorSubCode === 'OrganizationMaxUserCount') {
            displayError =
               'Your organization has reached the maximum paid user limit. Please contact us via the chat icon at the bottom to purchase additional user licenses.';
         } else if (exception?.errorSubCode === 'OrganizationMaxRoomCount') {
            displayError =
               'Your organization has reached the maximum displays limit. Please contact us via the chat icon at the bottom to add additional displays.';
         }
      }

      if (displayError) this.notificationService.error(displayError);
   }

   private jitter(min: number, max: number) {
      const minJ = Math.ceil(min);
      const maxJ = Math.floor(max);
      return Math.floor(Math.random() * (maxJ - minJ + 1)) + minJ;
   }
}
