import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';
import { RouterService } from '../services/router.service';

@Injectable({ providedIn: 'root' })
export class HttpXHeaderInterceptor {
   constructor(
      private auth: AuthService,
      private routerService: RouterService,
      private route: ActivatedRoute,
      private configService: ConfigService
   ) {}

   public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // don't attach to requests outside our api
      if (!this.configService.apiUtil.isHapticApiRequest(request)) return next.handle(request);

      request = request.clone({ headers: request.headers.set('X-Alleo-Client', this.configService.clientId) });

      if (this.routerService?.currentPageContext)
         request = request.clone({ headers: request.headers.set('X-Alleo-Page', this.routerService.currentPageContext.name) });

      return next.handle(request);
   }
}
