@if (!isHidden) {
   <div fxLayout="column" class="navigation-menu" [class.navigation-menu-collapsed]="isCollapsed">
      <div class="navigation-menu-top" [class.navigation-menu-top-collapsed]="isCollapsed">
         <div class="logo" (click)="expandMenu()">
            <img class="logo-icon" src="assets/images/logos/logo_alleo_a.svg" />
         </div>
         @if (!isCollapsed) {
            <div class="collapse-menu-icon">
               <button (click)="sidenavContainer.close()" mat-icon-button matTooltip="Close menu" fxHide.gt-sm>
                  <mat-icon>arrow_back</mat-icon>
               </button>
               <button (click)="collapseMenu()" mat-icon-button matTooltip="Collapse menu" fxHide.lt-md>
                  <mat-icon>menu</mat-icon>
               </button>
            </div>
         }
         @if (!isCollapsed) {
            <div class="user-info">
               <div>Welcome</div>
               <div class="secondary-text">{{ getUserName() }}</div>
               <div>
                  <mat-icon class="avatar-image">account_circle</mat-icon>
               </div>
            </div>
         }
      </div>
      <mat-nav-list>
         @for (item of navigationService.navigationItems | async; track item) {
            @if (!item.children) {
               <ng-container [ngTemplateOutlet]="linkTemplate" [ngTemplateOutletContext]="{ item }"> </ng-container>
            }
            @if (item.children) {
               <a
                  mat-list-item
                  href="#"
                  (click)="item.open = !item.open; $event.preventDefault()"
                  [class.open]="item.open"
                  [matTooltip]="isCollapsed ? item.title : ''"
               >
                  <mat-icon matListItemIcon>expand_{{ !item.open ? 'less' : 'more' }}</mat-icon>
                  @if (!isCollapsed) {
                     <span class="menu-button-label">{{ item.title }}</span>
                  }
               </a>
               @if (item.open) {
                  @for (child of item.children; track child) {
                     <ng-container [ngTemplateOutlet]="linkTemplate" [ngTemplateOutletContext]="{ item: child, nested: true }">
                     </ng-container>
                  }
               }
            }
         }
      </mat-nav-list>
      <ng-container> </ng-container>
   </div>
}

<ng-template #linkTemplate let-item="item" let-nested="nested">
   @if (item.url.indexOf('/') === 0) {
      <a
         mat-list-item
         [routerLink]="item.url"
         routerLinkActive="mdc-list-item--activated"
         [matTooltip]="isCollapsed ? item.title : ''"
         [class.nested]="nested"
      >
         <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
         @if (!isCollapsed) {
            <span class="menu-button-label">{{ item.title }}</span>
         }
      </a>
   }

   @if (item.url.indexOf('http') === 0) {
      <a mat-list-item [href]="item.url" target="_blank" [matTooltip]="isCollapsed ? item.title : ''" [class.nested]="nested">
         <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
         @if (!isCollapsed) {
            <span class="menu-button-label">{{ item.title }}</span>
         }
      </a>
   }
</ng-template>
