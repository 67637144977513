import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatButton } from '@angular/material/button';

@Component({
   selector: 'app-notification',
   templateUrl: './hap-notification.component.html',
   styleUrls: ['./hap-notification.component.scss'],
   animations: [
      trigger('flyInOut', [
         state(
            'inactive',
            style({
               opacity: 0
            })
         ),
         state(
            'active',
            style({
               opacity: 1
            })
         ),
         state(
            'removed',
            style({
               opacity: 0
            })
         ),
         transition('inactive => active', animate('200ms ease-out')),
         transition('active => removed', animate('200ms ease-out'))
      ])
   ],
   preserveWhitespaces: false,
   standalone: true,
   imports: [MatButton]
})
export class HapNotificationComponent extends Toast {
   public actions = [];

   constructor(
      protected toastrService: ToastrService,
      public toastPackage: ToastPackage
   ) {
      super(toastrService, toastPackage);
   }

   action(event: Event, actionStr: string) {
      event.stopPropagation();
      this.toastPackage.triggerAction(actionStr);
      return false;
   }

   // disable hover behaviors around progressbar/closing
   stickAround() {}
   delayedHideToast() {}
}
