<div fxLayout="row" fxLayoutAlign="start center">
   <!-- <div *ngIf="title" [class]="options.titleClass">
  {{ title }}
</div> -->

   @if (message && options.enableHtml) {
      <div fxFlex="1 0 0" [class]="options.messageClass" [innerHTML]="message"></div>
   }
   @if (message && !options.enableHtml) {
      <div fxFlex="1 0 0" [class]="options.messageClass">
         {{ message }}
      </div>
   }

   @for (actionStr of actions; track actionStr) {
      <button mat-button (click)="action($event, actionStr)">
         {{ actionStr }}
      </button>
   }
</div>
@if (options.progressBar) {
   <div>
      <div class="toast-progress" [style.width]="width + '%'"></div>
   </div>
}
