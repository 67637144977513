<div fxFlex>
   @if (imperonatingActive) {
      <button (click)="cancelImpersonation()" color="warn" mat-raised-button>
         Impersonating {{ getOrgName() }}
         <mat-icon>clear</mat-icon>
      </button>
   }
</div>

<button [matMenuTriggerFor]="userMenu" class="user-button" mat-button>
   <mat-icon>account_circle</mat-icon>
   <div fxLayout="row" fxLayoutAlign="center center">
      <span fxHide fxShow.gt-sm style="text-transform: uppercase">{{ getUserName() }}</span>
      <mat-icon fxHide.xs>keyboard_arrow_down</mat-icon>
   </div>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false">
   @if (isSaveReportAvailable()) {
      <button (click)="downloadReport()" mat-menu-item>
         <mat-icon>summarize</mat-icon>
         <span>Save admin report </span>
      </button>
   }

   @if (isSuperAdmin) {
      <button (click)="validateReport()" mat-menu-item>
         <mat-icon>check</mat-icon>
         <span> Verify admin report </span>
      </button>
   }

   <button (click)="onClickLogout()" mat-menu-item>
      <mat-icon>exit_to_app</mat-icon>
      <span> Logout </span>
   </button>
</mat-menu>
