import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
   constructor(private authService: AuthService) {}

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with jwt token if available
      const currentUser = this.authService.currentUserValue;
      if (currentUser && currentUser.authToken) {
         request = request.clone({
            setHeaders: {
               Authorization: `Bearer ${currentUser.authToken.token}`
            }
         });
      }

      return next.handle(request);
   }
}
