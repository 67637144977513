import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
   providedIn: 'root'
})
export class StyleLoaderService {
   constructor(private auth: AuthService) {
      this.auth.currentUser.subscribe((u) => {
         if (u) this.loadStyle('syncfusion.css');
      });
   }

   private loadedStyles = new Set();

   public loadStyle(url: string) {
      if (this.loadedStyles.has(url)) return;

      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      document.head.appendChild(link);

      this.loadedStyles.add(url);
   }
}
