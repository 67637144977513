import { APP_INITIALIZER, enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { registerLicense } from '@syncfusion/ej2-base';
import { AppComponent } from 'app/app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { PreloadAllModules, UrlSerializer, provideRouter, withPreloading } from '@angular/router';
import { JwtInterceptor, HttpErrorInterceptor } from 'app/helpers';
import { HttpXHeaderInterceptor } from 'app/helpers/http-x-header-interceptor';
import { LowerCaseUrlSerializer } from 'app/helpers/lower-case-url-serializer';
import { BaseConfigProvider } from 'app/services/base-config-provider.service';
import { ConfigService } from 'app/services/config.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { APP_ROUTES } from 'app/app.routes';
import { provideToastr } from 'ngx-toastr';
import { HapNotificationComponent } from 'app/core/components/hap-notification/hap-notification.component';
import { provideNativeDateAdapter } from '@angular/material/core';

registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdExiXH1YcHBcQWJU');

export const configObj = { baseApiUrl: null, environment: null };
export const globalConfigObj: any = {};
export const versionObj = { releaseVersion: null };

if (environment.production) {
   enableProdMode();
}

function loadConfig(configProvider: BaseConfigProvider, configService: ConfigService): () => Promise<boolean> {
   return async (): Promise<boolean> => {
      let baseUri = document.baseURI;
      if (!baseUri.endsWith('/')) baseUri += '/';

      const [configObj, versionObj] = await Promise.all([
         fetch(baseUri + 'assets/config.json').then((r) => r.json()),
         fetch(baseUri + 'assets/version.json').then((r) => r.json())
      ]);

      const globalConfigObj = await (await fetch(`${configObj.baseApiUrl}configs/client/global?domain=${window.location.hostname}`)).json();

      configProvider.baseApiUrl = configObj.baseApiUrl;
      configProvider.environment = configObj.environment;
      configProvider.version = versionObj.releaseVersion;
      configProvider.globalConfig = globalConfigObj;

      try {
         await configService.initialize();
      } catch (e) {
         console.error('Initialize config failed', e);
         return false;
      }
      return true;
   };
}

bootstrapApplication(AppComponent, {
   providers: [
      provideNativeDateAdapter(),
      provideAnimations(),
      provideToastr({
         toastComponent: HapNotificationComponent,
         positionClass: 'toast-bottom-center',
         newestOnTop: false
      }),
      provideHttpClient(withInterceptorsFromDi()),
      BaseConfigProvider,
      ConfigService,
      {
         provide: APP_INITIALIZER,
         useFactory: loadConfig,
         deps: [BaseConfigProvider, ConfigService],
         multi: true
      },
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: HttpXHeaderInterceptor,
         multi: true
      },
      {
         provide: UrlSerializer,
         useClass: LowerCaseUrlSerializer
      },
      {
         provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
         useValue: {
            color: 'primary'
         } as MatCheckboxDefaultOptions
      },
      provideRouter(APP_ROUTES, withPreloading(PreloadAllModules))
   ]
});
