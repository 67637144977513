import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../services';
import { NavigationService } from '../../../services/navigation.service';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NavigationStart, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { MatNavList, MatListItem, MatListItemIcon } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
   selector: 'app-nav-menu',
   templateUrl: './nav-menu.component.html',
   styleUrls: ['./nav-menu.component.scss'],
   encapsulation: ViewEncapsulation.None,
   standalone: true,
   imports: [
      FlexModule,
      MatIconButton,
      MatTooltip,
      ExtendedModule,
      MatIcon,
      MatNavList,
      NgTemplateOutlet,
      MatListItem,
      MatListItemIcon,
      RouterLinkActive,
      RouterLink,
      AsyncPipe
   ]
})
export class NavMenuComponent {
   public isCollapsed = false;

   constructor(
      private authService: AuthService,
      public navigationService: NavigationService,
      public sidenavContainer: MatSidenavContainer,
      private breakpointObserver: BreakpointObserver,
      private router: Router
   ) {
      this.router.events.subscribe((e) => {
         if (e instanceof NavigationStart && this.isSmallLayout) this.sidenavContainer.close();
      });
   }

   public get isSmallLayout() {
      return this.breakpointObserver.isMatched([Breakpoints.XSmall, Breakpoints.Small]);
   }

   collapseMenu() {
      this.isCollapsed = true;
   }

   expandMenu() {
      if (this.isSmallLayout) {
         this.sidenavContainer.close();
      } else {
         this.isCollapsed = false;
      }
   }

   getUserName(): string {
      return this.authService.getUserName();
   }

   get isHidden(): boolean {
      return this.authService.currentUserValue == null;
   }
}
