import { Routes } from '@angular/router';

import { AuthGuard } from './guards/auth-guard.service';

export const APP_ROUTES: Routes = [
   { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
   { path: 'auth', loadChildren: () => import('./main/pages/authentication/authentication.routes').then((m) => m.ROUTES) },
   {
      path: 'pages/analytics',
      loadChildren: () => import('./main/pages/analytics/analytics.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/ldap-settings-list',
      loadChildren: () => import('./main/pages/ldap-settings/ldap-settings.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/configurations',
      loadChildren: () => import('./main/pages/configurations/configurations.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/org',
      loadChildren: () => import('./main/pages/organization/organization.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/alleo-rooms',
      loadChildren: () => import('./main/pages/meeting-room/meeting-room.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/languages',
      loadChildren: () => import('./main/pages/languages/languages.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/capabilities',
      loadChildren: () => import('./main/pages/capabilities/capabilities.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/guides',
      loadChildren: () => import('./main/pages/guides/guides.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/user-plans',
      loadChildren: () => import('./main/pages/user-plans/user-plans.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/group',
      loadChildren: () => import('./main/pages/group/groups.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/partners',
      loadChildren: () => import('./main/pages/partner/partner.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/users',
      loadChildren: () => import('./main/pages/users/users.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/alleo-rooms',
      loadChildren: () => import('./main/pages/meeting-room/meeting-room.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/board-template-files',
      loadChildren: () => import('./main/pages/board-template-files/board-template-files.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/themes',
      loadChildren: () => import('./main/pages/themes/themes.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/invites',
      loadChildren: () => import('./main/pages/invites/invites.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/pending-users',
      loadChildren: () => import('./main/pages/pending-users/pending-users.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   {
      path: 'pages/third-party-connections',
      loadChildren: () => import('./main/pages/third-party-connections/third-party-connections.routes').then((m) => m.ROUTES),
      canActivate: [AuthGuard]
   },
   { path: '**', redirectTo: 'auth/login' }
];
