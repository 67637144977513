import { Platform } from '@angular/cdk/platform';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { SplashScreenService } from './services/splash-screen.service';
import { AuthService } from './services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { StyleLoaderService } from './services/style-loader.service';
import { MatToolbar } from '@angular/material/toolbar';
import { ToolbarComponent } from './layout/components/toolbar/toolbar.component';
import { NavMenuComponent } from './layout/components/nav-menu/nav-menu.component';
import { RouterOutlet } from '@angular/router';

@Component({
   selector: 'app-admin',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
   encapsulation: ViewEncapsulation.None,
   standalone: true,
   imports: [MatToolbar, MatSidenav, MatSidenavContainer, MatSidenavContent, ToolbarComponent, NavMenuComponent, AsyncPipe, RouterOutlet]
})
export class AppComponent implements OnDestroy {
   private _unsubscribeAll: Subject<void> = new Subject();

   @ViewChild(MatSidenavContainer)
   public sidenavContainer: MatSidenavContainer;

   public belowMd = this.breaktpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);
   public sidenavOpened = true;

   constructor(
      public styleLoaderService: StyleLoaderService,
      public splashScreenService: SplashScreenService,
      public authService: AuthService,
      @Inject(DOCUMENT) private document: Document,
      private platform: Platform,
      private breaktpointObserver: BreakpointObserver
   ) {
      console.log(`%c${environment.buildVersion}`, 'font-size: 2em; font-weight: bold; color: #000000');

      if (this.platform.ANDROID || this.platform.IOS) {
         this.document.body.classList.add('is-mobile');
      }

      this.belowMd.subscribe(({ matches }) => {
         if (!matches) this.sidenavContainer?.open();
      });
   }

   ngOnDestroy(): void {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
   }
}
